import { useQuery, useSuspenseQuery } from "@tanstack/react-query"
import { useMemo } from "react"
import { Location, useLocation } from "react-router-dom"

import queryClient from "@/api/client"
import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import AppEditLayout from "@/app/layouts/AppEditLayout/AppEditLayout"
import PageLayout from "@/app/layouts/PageLayout/PageLayout"
import { AppInstanceView } from "@/areas/app"
import { LocationContainer, LocationGrouped, LocationGroupedComponentProps } from "@/areas/location"
import { MenuAppSettingsContainer, MenuAppsGroup, PopupCreateMenuApp } from "@/areas/menu"
import { AppInstance } from "@/entities/app/app.types"
import MenuAppDAO from "@/entities/app/menu/menu.dao"
import { confirmAction } from "@/services/Modal/components/PopupConfirm"
import Modal from "@/services/Modal/Modal"
import Theme from "@/services/Theme/Theme"
import ButtonIcon from "@/shared/components/intrinsic/Button/ButtonIcon"
import useParam from "@/utils/hooks/useParam"
import useSearchState from "@/utils/hooks/useSearchState"



interface LocationState {
  dirty?: boolean
}


function MenusPage() {
  const companyId = useParam("id")
  const [appId] = useSearchState("appId")

  const location = useLocation() as Location<LocationState>
  location.state ??= {}

  const sidebar = (
    <DefaultBoundaries>
      {!!appId && <MenuAppSettingsContainer id={appId} onChange={() => location.state.dirty = true} />}
    </DefaultBoundaries>
  )

  return (
    <AppEditLayout sidebar={sidebar} sidebarActive={!!appId}>
      <PageLayout>
        <h2>Menu Apps</h2>
        <Theme scheme="dark">
          <DefaultBoundaries>
            <LocationGrouped companyId={companyId} children={MenuAppsByLocation} />
          </DefaultBoundaries>
        </Theme>
      </PageLayout>
    </AppEditLayout>
  )
}

export default MenusPage


const menuAppDao = new MenuAppDAO

function MenuAppsByLocation(props: LocationGroupedComponentProps) {
  const [appId] = useSearchState("appId")

  const { data: menu } = useQuery({
    queryFn: () => appId != null ? menuAppDao.findById(appId) : null,
    queryKey: [menuAppDao.name, appId]
  })

  return (
    <LocationContainer {...props} defaultExpanded={props.location.id === menu?.locationId}>
      <DefaultBoundaries>
        <MenuAppsContainer {...props} />
      </DefaultBoundaries>
    </LocationContainer>
  )
}

function MenuAppsContainer(props: LocationGroupedComponentProps) {
  const [menuAppId, setMenuAppId] = useSearchState("appId")
  const location = useLocation() as Location<LocationState>

  const queryKey = [menuAppDao.name, "location", props.location.id, "groupedByScreen"]
  const { data: menuApps } = useSuspenseQuery({
    queryFn: () => menuAppDao.findByLocation(props.location.id),
    queryKey
  })
  const menuAppsGroupedByAppId = useMemo(() => Map.groupBy(menuApps, app => app.name), [menuApps])


  async function onMenuAppSelect(menuAppId: string) {
    if (location.state?.dirty) {
      await confirmAction()
    }

    setMenuAppId(menuAppId)
  }

  async function createMenuApp(appId: string) {
    const menuApp = await menuAppDao.create({
      name: appId,

      companyId: props.companyId,
      locationId: props.location.id
    })

    const queryKey = [menuAppDao.name, "location", props.location.id]
    queryClient.setQueriesData<AppInstance[]>({ queryKey, exact: false }, apps => apps && [...apps, menuApp])
  }

  function onMenuAppScreenCreate() {
    Modal.open(PopupCreateMenuApp, { ...props, onCreate: setMenuAppId })
  }

  return (
    <>
      <ButtonIcon size="small" name="plus" ariaLabel="Create Screen" onClick={onMenuAppScreenCreate} />
      {menuAppsGroupedByAppId.size === 0 && (
        <span>There are no Menus in this location.</span>
      )}
      {[...menuAppsGroupedByAppId.keys()].map(appId => (
        <AppInstanceView type="MENU" name={appId} key={appId}>
          <MenuAppsGroup appId={appId} instanceId={menuAppId} instances={menuAppsGroupedByAppId.get(appId)!} onSelect={onMenuAppSelect} onCreate={() => createMenuApp(appId)} />
        </AppInstanceView>
      ))}
    </>
  )
}
