import "./MenuAppsGroup.scss"

import { MenuAppInstance } from "@/entities/app/menu/menu.types"
import ButtonIcon from "@/shared/components/intrinsic/Button/ButtonIcon"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import { classWithModifiers } from "@/utils/common"



interface MenuAppsGroupProps {
  appId: string
  instances: MenuAppInstance[]

  instanceId?: string
  onSelect?(id: string): void
  onCreate?(): void | Promise<void>
}

function MenuAppsGroup(props: MenuAppsGroupProps) {
  return (
    <div className="menu-apps-group">
      <ButtonIcon size="smaller" name="plus" ariaLabel="Add menu app" await onClick={props.onCreate} />
      {props.instances.map(instance => (
        <button
          type="button"
          className={classWithModifiers("menu-apps-group__instance", props.instanceId === instance.id && "active")}
          onClick={() => props.onSelect?.(instance.id)}
          key={instance.id}
        >
          <Icon name="image" />
        </button>
      ))}
    </div>
  )
}

export default MenuAppsGroup
